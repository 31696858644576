.home-uplift-brand {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
}

.uplift-container {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.uplift-container h1 {
  color: #ffd9d9;
  font-size: 3em;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  margin-top: 100px;
  padding: 10px;
}

.uplift-container p {
  color: white;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
  padding: 15px;
}

.uplift-container h2 {
  color: white;
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  margin-top: 50px;
}

.work-points {
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  gap: 40px 25px;
  justify-content: space-around;
  margin: 30px 0px;
  padding: 10px;
}

.work-points .point {
  background-color: rgba(175, 19, 242, 0.3);

  min-height: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 3px 10px 0px #ff00ff;
}

.work-points .point h3 {
  color: white;
  font-size: 1.15em;
  font-weight: 700;
  text-align: center;
  padding: 10px;
}

.work-points .point p {
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1.5em;
}

.btn-uplift {
  background-color: #ffd9d9;
  border: none;
  width: 250px;
  padding: 15px;
  margin: 50px auto;
}

.btn-uplift h4 {
  font-size: 0.9em;
  font-weight: 700px;
  letter-spacing: 1.5px;
  color: #af13f2;
  margin-top: 0px;
}

.btn-uplift:hover {
  background-color: #af13f2;
  border: 1px solid #ffd9d9;
}

.btn-uplift:hover h4 {
  color: #ffd9d9;
}

@media screen and (max-width: 768px) {
  .uplift-container h1 {
    font-size: 2.7em;
  }
}

@media screen and (max-width: 576px) {
  .uplift-container h1 {
    font-size: 2.3em;
  }
}
