.header {
  position: fixed;
  top: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  z-index: 1;
}

.container {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  height: 40px;
}

.space {
  background-color: #000000;
  opacity: 3;
}

.header-right {
  display: flex;
}

nav .menu-list {
  list-style: none;
  display: flex;
}

nav .menu-list .menu-item {
  padding: 5px 5px;
}

.header .container .header-right nav .menu-list .header-link:hover,
.header-link.active {
  color: #ffd9d9;
  border-bottom: 1px solid #ffd9d9;
}

.header-link {
  text-decoration: none;
  font-weight: 700;
  font-size: 10px;
  color: white;
  margin-right: 30px;
}

.header.grad {
  background-color: #fffbfb;
}

.header.grad .container .header-right nav .menu-list .header-link {
  color: #5928e5;
}

.header.grad .container .header-right nav .menu-list .header-link:hover,
.header-link.active {
  border-bottom: 1px solid #5928e5;
}

.fa-bars {
  color: white;
  width: 25px;
  height: 25px;
  display: none;
  margin-top: 5px;
  float: right;
}

.header.grad .container .header-right .fa-bars {
  color: #5928e5;
}

.header-btn {
  text-decoration: none;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 10px;
  color: white;
  background-color: #f213ee;
  border: 1px solid #f213ee;
  margin-right: 15px;
}

.header-btn:hover {
  background-color: #5928e5;
}

.header-social-links {
  display: none;
}

@media screen and (max-width: 992px) {
  .container {
    width: 90%;
  }

  .header-btn {
    padding: 11px 10px;
    font-size: 7px;
  }

  .header-link {
    font-size: 8px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .fa-bars {
    display: block;
  }

  .header-right {
    display: flex;
    flex-direction: row-reverse;
  }

  .header-logo {
    height: 35px;
  }

  nav {
    position: absolute;
    right: 0;
    top: 60px;
    background-image: linear-gradient(to bottom, #5928e5, #af13f2);
    height: 100vh;
    width: 40%;
    transform: translateX(100%);
    z-index: 1;
  }

  nav .menu-list {
    flex-direction: column;
  }

  nav .menu-list .menu-item {
    width: 100%;
    padding: 10px 30px;
    border-bottom: 1px solid #461eb4;
  }

  .header.grad .container .header-right nav .menu-list .header-link {
    color: white;
  }

  .header.grad .container .header-right nav .menu-list .header-link:hover,
  .header-link:active {
    color: #ffd9d9;
  }

  nav.show {
    animation: showMenu 250ms ease-in forwards;
  }

  nav.hide {
    animation: hideMenu 250ms ease-in forwards;
  }

  @keyframes showMenu {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  @keyframes hideMenu {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(100%);
    }
  }
}

@media screen and (max-width: 576px) {
  nav {
    width: 100%;
    top: 60px;
  }

  .header-logo {
    height: 30px;
  }

  .header {
    height: 60px;
  }

  .header-social-links {
    display: block;
    position: absolute;
    bottom: 100px;
    margin-top: 30px;
    width: 100%;
  }
}
