.reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.reviews-container {
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  height: 200px;
  padding: 10px;
}

.owner-statment {
  flex: 45%;
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: 3px 3px 7px 0px #949494;
}

.owner-statment p {
  width: 75%;
  font-size: 1em;
  font-weight: 400;
  color: white;
  text-align: center;
}

.owner-statment h4 {
  font-size: 1.1em;
  font-weight: 600;
  color: #ffd9d9;
}

.client-reviews {
  flex: 45%;
  background-color: #ffd9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 3px 3px 7px 0px #949494;
}

.client-reviews .arrow {
  flex: 10%;
  padding: 90px 10px;
}

.client-reviews .arrow:hover {
  color: #5928e5;
}

.client-reviews .review {
  flex: 70%;
  position: relative;
}

.client-reviews .review p {
  font-size: 0.8em;
  font-weight: 400;
  color: #7e7e7e;
  text-align: center;
}

.client-reviews .review h4 {
  font-size: 1em;
  font-weight: 700;
  color: #5928e5;
  text-align: center;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .reviews-container {
    flex-direction: column-reverse;
    gap: 0px;
    height: 400px;
  }

  .owner-statment {
    flex: 50%;
  }

  .owner-statment p {
    width: 85%;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 576px) {
  .client-reviews .arrow {
    flex: 5%;
  }
  .client-reviews .review {
    flex: 85%;
  }
}
