.portfolio {
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.portfolio-container {
  max-width: 1125px;
  width: 100%;
  margin-top: 100px;
  padding: 20px;
}

.portfolio-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 270px;
  height: auto;
  padding: 10px;
}

.portfolio-item .item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.portfolio-item .item-container .left-content {
  padding: 10px;
}

.portfolio-item:nth-child(even) {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
}

.portfolio-item:nth-child(even) .item-container {
  flex-direction: row-reverse;
}

.portfolio-item:nth-child(even) .item-container .left-content .product {
  color: #ffd9d9;
  margin-bottom: 20px;
  font-size: 3em;
}

.portfolio-item:nth-child(even) .item-container .left-content .headline {
  font-weight: 500;
  font-size: 1.1em;
  color: white;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.portfolio-item:nth-child(even) .item-container .left-content .summary {
  font-size: 0.8em;
  font-weight: 300;
  line-height: 1.4em;
  color: white;
}

.portfolio-item:nth-child(even) .item-container .left-content .statement {
  font-size: 0.9em;
  font-weight: 400;
  color: white;
  margin-top: 25px;
  text-align: center;
}

.portfolio-item:nth-child(even) .item-container .left-content .founder {
  font-size: 0.65em;
  font-weight: 500;
  color: white;
  text-align: center;
}

.left-content {
  padding: 30px;
  width: 95%;
}

.portfolio-item:nth-child(odd) .item-container .left-content .product {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
  font-size: 3em;
}

.portfolio-item:nth-child(odd) .item-container .left-content .headline {
  font-weight: 500;
  font-size: 1.1em;
  color: #656565;
  line-height: 1.2em;
  margin-bottom: 20px;
}

.portfolio-item:nth-child(odd) .item-container .left-content .summary {
  font-size: 0.9em;
  font-weight: 400;
  color: #6b6b6b;
}

.portfolio-item:nth-child(odd) .item-container .left-content .statement {
  font-size: 0.95em;
  font-weight: 400;
  color: #7e7e7e;
  margin-top: 25px;
  text-align: center;
}

.portfolio-item:nth-child(odd) .item-container .left-content .founder {
  font-size: 0.9em;
  font-weight: 500;
  color: #6b6b6b;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .portfolio-container {
    max-width: 700px;
    width: 100%;
    margin-top: 100px;
    padding: 20px;
  }

  .portfolio-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 200px;
  }

  .item-container {
    flex-direction: column;
  }

  .portfolio-item:nth-child(even) .item-container {
    flex-direction: column;
  }

  .left-content {
    padding: 40px;
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .portfolio-container {
    padding: 0px;
  }

  .item-container {
    gap: 0px;
  }

  .left-content {
    padding: 0px;
  }

  .product,
  .headline,
  .summary,
  .statement,
  .founder {
    padding: 10px;
    margin-bottom: 0px;
  }
}
