.youtube-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffd9d9;
  padding-bottom: 100px;
}

.video-container {
  max-width: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
}

.video {
  width: 45%;
  height: 300px;
}

.video-product {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
  font-size: 3em;
  padding: 50px;
}

@media screen and (max-width: 768px) {
  .youtube-section {
    padding: 0px 0px 100px 0px;
  }

  .video-container {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .video {
    width: 90%;
    height: 300px;
  }
}

@media screen and (max-width: 576px) {
  .video {
    width: 90%;
    height: 250px;
  }
}
