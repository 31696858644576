.booking {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  background-image: linear-gradient(
    to top,
    rgba(89, 40, 229, 0.5),
    rgba(175, 19, 242, 0.5)
  );
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  width: 15px;
  height: 15px;
  padding: 10px;
}

.close:hover {
  color: rgb(255, 53, 53);
}

.form-container {
  position: relative;
  flex: 50%;
  display: flex;
  justify-content: center;
  max-width: 450px;
  width: 100%;
  z-index: 1;
}

.form-container .booking-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to top, #5928e5, #af13f2);
  padding: 20px 50px;
  max-width: 450px;
  height: auto;
}

.booking-form h3 {
  color: #ffd9d9;
  font-size: 1.3em;
  font-weight: 800;
  margin: 10px;
}

.booking-form input {
  width: 330px;
  height: 30px;
  margin-bottom: 10px;
  color: #5928e5;
  padding: 5px 10px;
  font-size: 0.8em;
  font-weight: 300;
  border: none;
}

.booking-form input:focus {
  border: none;
  outline: none;
}

.booking-form textarea {
  max-width: 330px;
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  color: #5928e5;
  padding: 5px 10px;
  font-size: 0.8em;
  font-weight: 300;
  border: none;
}

.booking-form textarea {
  outline: none;
  resize: none;
}

.submit-btn {
  background-color: #ffd9d9;
  border: none;
  width: 250px;
  padding: 10px 15px;
}

.submit-btn h4 {
  font-size: 0.9em;
  font-weight: 700px;
  letter-spacing: 1.5px;
  color: #5928e5;
  margin-top: 0px;
}

.submit-btn:hover {
  background-color: #5928e5;
  border: 1px solid #ffd9d9;
}

.submit-btn:hover h4 {
  color: #ffd9d9;
}

@media screen and (max-width: 576px) {
  .booking {
    margin-top: 60px;
  }
}
