.footer {
  width: 100%;
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.footer::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;
  background-image: linear-gradient(to top, #5928e5, #af13f2);
}

.footer-container {
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0px;
  gap: 100px;
}

.left-links {
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.social-media-links .icon-list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.social-media-links .icon-list .list-item a .icon {
  color: white;
  width: 30px;
  height: 30px;
}

.right-form {
  flex: 50%;
  display: flex;
  justify-content: center;
}

.right-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to top, #5928e5, #af13f2);
  padding: 20px 50px;
  max-width: 450px;
}

form h3 {
  color: #fffbfb;
  font-size: 1.5em;
  font-weight: 800;
  margin-bottom: 15px;
}

form input {
  width: 330px;
  height: 30px;
  margin-bottom: 10px;
  color: #5928e5;
  padding: 5px 10px;
  font-size: 0.8em;
  font-weight: 300;
  border: none;
}

form input:focus {
  border: none;
  outline: none;
}

form textarea {
  max-width: 330px;
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  color: #5928e5;
  padding: 5px 10px;
  font-size: 0.8em;
  font-weight: 300;
  border: none;
}

form textarea {
  outline: none;
  resize: none;
}

.submit-btn {
  background-color: #ffd9d9;
  border: none;
  width: 250px;
  padding: 10px 15px;
}

.submit-btn h4 {
  font-size: 0.9em;
  font-weight: 700px;
  letter-spacing: 1.5px;
  color: #5928e5;
  margin-top: 0px;
}

.submit-btn:hover {
  background-color: #5928e5;
  border: 1px solid #ffd9d9;
}

.submit-btn:hover h4 {
  color: #ffd9d9;
}

.left-links {
  height: 100%;
}

.left-links .footer-logo img {
  height: 50px;
}

.links {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.links .link h3 {
  color: white;
  font-size: 1em;
}

.links .link ul {
  list-style: none;
}

.links .link ul li {
  color: white;
  font-size: 0.7em;
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .footer-container {
    max-width: 950px;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
    gap: 50px;
    padding: 10px;
  }
}

@media screen and (max-width: 576px) {
  .right-form {
    width: 100%;
  }
  .right-form form {
    width: 100%;
    padding: 20px 20px;
  }

  form h3 {
    font-size: 1.2em;
  }

  form input {
    width: 100%;
  }

  form textarea {
    max-width: 100%;
  }

  .submit-btn {
    width: 100%;
  }

  .links {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    text-align: center;
  }
}
