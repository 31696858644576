.package {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 100px;
  margin: 100px 0px;
}

.package h1 {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.2em;
  font-weight: 700;
  text-align: center;
}

.package-item {
  max-width: 900px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.package-item.reverse {
  max-width: 900px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.package-item .left {
  flex: 50%;
  width: 100%;
  padding: 20px;
}

.package-item .left h2 {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 15px;
}

.package-item .left ul {
  list-style: none;
}

.package-item .left ul li {
  color: #7e7e7e;
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 5px;
}

.package-item .left ul li img {
  width: 12px;
  height: 100%;
  margin-right: 10px;
}

.package .right {
  position: relative;
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 70%;
}

.package .right svg {
  z-index: 0;
}

.package-item .right .bg {
  position: absolute;
  top: 0;
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  display: block;
  width: 40%;
  height: 100%;
  z-index: 0;
  animation: shrinkRight 750ms ease-in forwards;
}

@keyframes shrinkRight {
  0% {
    width: 80%;
  }
  100% {
    width: 40%;
    transform: translateX(50%);
  }
}

.package-item.reverse .right .bg {
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  display: block;
  width: 40%;
  height: 100%;
  z-index: 0;
  animation: shrinkLeft 750ms ease-in forwards;
}

@keyframes shrinkLeft {
  0% {
    width: 80%;
  }
  100% {
    width: 40%;
    transform: translateX(20%);
  }
}

@media screen and (max-width: 768px) {
  .package {
    gap: 100px;
  }

  .package-item,
  .package-item.reverse {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 20px;
  }

  .package-item .left {
    flex: 50%;
    width: 80%;
    padding: 20px;
  }

  .package h1 {
    font-size: 2.3em;
    font-weight: 900;
  }

  .package-item .left h2 {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 576px) {
  .package h1 {
    font-size: 2em;
  }

  .package-item .left p {
    width: 300px;
  }

  .package-item .left h2 {
    font-size: 1.3em;
  }

  .package-item .right svg {
    width: 200px;
    height: 200px;
  }

  .package-item .right .bg {
    width: 100%;
  }

  .package-item .left ul li {
    font-size: 0.9em;
  }

  .package-item .left {
    padding: 0px;
  }
}
