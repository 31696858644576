.calendly-inline-widget {
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  min-width: 320px;
  height: 750px;
  margin-bottom: 100px;
  padding: 20px;
}

.calendly-inline-widget,
.simplebar-content-wrapper {
  overflow: hidden !important;
}

@media screen and (max-width: 768px) {
  .calendly-inline-widget {
    height: 850px;
  }
}
