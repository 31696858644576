.expertise {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 100px;
  margin: 70px 0px 150px 0px;
}

.expertise h1 {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.2em;
  font-weight: 700;
  text-align: center;
}

.expertise-item {
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.expertise-item.reverse {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.expertise-item .left {
  flex: 50%;
  width: 100%;
  padding: 20px;
}

.expertise-item .left h2 {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 10px;
}

.expertise-item .left p {
  color: #7e7e7e;
  font-size: 1.1em;
  font-weight: 400;
  max-width: 450px;
  line-height: 1.5em;
}

.expertise-item .right {
  position: relative;
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 70%;
}

.expertise-item .right svg {
  z-index: 0;
}

.expertise-item .right .bg {
  position: absolute;
  top: 0;
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  display: block;
  width: 40%;
  height: 100%;
  z-index: 0;
  animation: shrinkRight 750ms ease-in forwards;
  box-shadow: 3px 3px 7px 0px #949494;
}

@keyframes shrinkRight {
  0% {
    width: 80%;
  }
  100% {
    width: 40%;
    transform: translateX(50%);
  }
}

.expertise-item.reverse .right .bg {
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  display: block;
  width: 40%;
  height: 100%;
  z-index: 0;
  animation: shrinkLeft 750ms ease-in forwards;
}

@keyframes shrinkLeft {
  0% {
    width: 80%;
  }
  100% {
    width: 40%;
    transform: translateX(20%);
  }
}

@media screen and (max-width: 768px) {
  .expertise {
    gap: 100px;
  }

  .expertise-item,
  .expertise-item.reverse {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .expertise-item .left {
    flex: 50%;
    width: 80%;
    padding: 20px;
  }

  .expertise h1 {
    font-size: 2.4em;
    font-weight: 900;
  }

  .expertise-item .left h2 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 576px) {
  .expertise h1 {
    font-size: 2em;
  }

  .expertise-item .left p {
    width: 300px;
  }

  .expertise-item .left h2 {
    font-size: 1.4em;
  }

  .expertise-item .right svg {
    width: 200px;
    height: 200px;
  }
}
