.home-hero {
  position: relative;
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  min-height: 70px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.home-hero-bg-lines {
  display: none;
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 0;
}

.hero-container {
  width: 75%;
  padding: 70px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-left {
  flex: 60%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.hero-left h1 {
  color: white;
  font-size: 3.4vw;
  font-weight: 700;
  line-height: 1.2em;
  margin-top: 100px;
}
.hero-left p {
  color: white;
  font-size: 1.1vw;
}

.hero-right {
  flex: 40%;
  width: 100%;
  height: 100%;
  transform: translateX(5%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-hero-illustration {
  width: 40vw;
  height: 100%;
  z-index: 0;
  animation: fadeIn 750ms ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .hero-container {
    padding: 40px 15px;
  }
}

@media screen and (max-width: 992px) {
  .home-hero {
    min-height: 450px;
  }

  .hero-left h1 {
    font-weight: 800;
  }

  .hero-container {
    width: 90%;
    padding: 20px 15px;
  }

  .hero-left p {
    color: white;
    font-size: 1vw;
  }
}

@media screen and (max-width: 768px) {
  .hero-left {
    flex: 30%;
  }
  .home-hero {
    min-height: 650px;
  }
  .home-hero-bg-lines {
    display: block;
    position: absolute;
    top: -50px;
    right: 0;
    z-index: 0;
  }

  .hero-left h1 {
    font-size: 3em;
  }

  .hero-left p {
    color: white;
    font-size: 2vw;
  }

  .hero-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .home-hero {
    justify-content: flex-start;
  }

  .hero-right {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .hero-left p {
    color: white;
    font-size: 3.3vw;
    font-weight: 500;
  }

  .hero-left h1 {
    font-size: 2.8em;
  }
}
