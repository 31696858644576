.home-bestseller-brand {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
}

.bestseller-container {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bestseller-container h1 {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3em;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  margin-top: 100px;
  padding: 10px;
}

.bestseller-container p {
  color: #7e7e7e;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
  padding: 10px;
}

.bestseller-container h2 {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2em;
  font-weight: 800;
  text-align: center;
  margin: 70px 0px 50px 0px;
}

.process-points {
  display: grid;
  grid-template-columns: repeat(auto-fill, 290px);
  gap: 40px 20px;
  justify-content: space-around;
  margin: 30px 0px;
}

.process-points .point {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process-points .point h3 {
  color: #5928e5;
  font-size: 1.3em;
  font-weight: 700;
  text-align: center;
  padding: 20px;
}

.process-points .point p {
  font-size: 0.8em;
  font-weight: 400;
  color: #7e7e7e;
}

.btn-bestseller {
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  border: none;
  width: 250px;
  padding: 15px;
  margin: 50px auto;
}

.btn-bestseller h4 {
  font-size: 0.9em;
  font-weight: 700px;
  letter-spacing: 1.5px;
  color: #fffbfb;
  margin-top: 0px;
}

.btn-bestseller:hover {
  background-image: none;
  background-color: #ffd9d9;
  border: 1px solid #5928e5;
}

.btn-bestseller:hover h4 {
  color: #5928e5;
}

@media screen and (max-width: 768px) {
  .bestseller-container h1 {
    font-size: 2.4sem;
  }
  .process-points {
    gap: 30px;
  }

  .process-points .point h3 {
    color: #5928e5;
    font-size: 1.2em;
  }

  .bestseller-container h2 {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 576px) {
  .bestseller-container h1 {
    font-size: 2em;
    padding: 5px;
  }

  .bestseller-container p {
    padding: 20px;
    font-size: 0.8em;
  }

  .process-points {
    gap: 50px;
  }

  .process-points .point h3 {
    font-size: 1.1em;
  }

  .bestseller-container h2 {
    font-size: 1.6em;
  }
}
