.portfolio-hero {
  position: relative;
  background-image: linear-gradient(to bottom, #5928e5, #af13f2);
  min-height: 410px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.portfolio-hero-bg {
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 0;
}

.portfolio-hero-container {
  width: 75%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.portfolio-hero-left {
  flex: 50%;
  z-index: 0;
}

.portfolio-hero-left h1 {
  color: white;
  font-size: 3.2em;
  font-weight: 700;
  line-height: 1.3em;
  margin-top: 150px;
  text-align: center;
}

.portfolio-hero-right {
  flex: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(5%);
  display: flex;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .portfolio-hero-left h1 {
    font-size: 2.5em;
    font-weight: 800;
  }
  .portfolio-hero-container {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .portfolio-hero-left {
    flex: 30%;
  }

  .portfolio-hero-left h1 {
    font-size: 2em;
  }

  .portfolio-hero-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .portfolio-hero {
    justify-content: flex-start;
  }

  .portfolio-hero-right {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .portfolio-hero-left h1 {
    font-size: 3em;
  }
}
