.down-arrow {
  margin-top: 50px;
  animation: toAndFro 1s ease-in infinite;
}

.alert {
  position: fixed;
  top: 90px;
  color: white;
  border-radius: 10px;
  font-size: 0.7em;
  font-weight: 500;
  padding: 5px 10px;
}

.alert.success {
  background-color: rgb(0, 165, 0);
}

.alert.failure {
  background-color: rgb(192, 0, 0);
}

@keyframes toAndFro {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.fade-in {
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.scaleup {
  animation: scaleUp 1.2s ease-in-out forwards;
}

@keyframes scaleUp {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
