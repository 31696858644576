* {
  font-family: "Poppins", sans-serif;

  line-height: 1.4em;
  margin: 0;
  padding: 0;
}

.app {
  background-color: #fffbfb;
}

.whatsapp-link {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.whatsapp-link p {
  color: #00a208;
  background-color: white;
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 0.7em;
}

.whatsapp-icon {
  color: #02aa0b;
  width: 30px;
  height: 30px;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  border: 2px solid #02aa0b;
}

@media screen and (max-width: 576px) {
  .whatsapp-link {
    bottom: 20px;
    right: 20px;
  }
}
