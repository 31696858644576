.about-hero {
  position: relative;
  background-image: linear-gradient(to right, #5928e5, #af13f2);
  overflow: hidden;
  min-height: 710px;
  display: flex;
  justify-content: center;
}

.about-hero-bg {
  position: absolute;
  top: -50px;
  right: 0;
}

.about-hero-container {
  position: sticky;
  width: 65%;
  height: 80%;
  padding: 15px;
  margin: 200px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 0;
}

.about-hero-container::-webkit-scrollbar {
  display: none;
}

.about-hero-container section {
  display: flex;
  height: 375px;
  margin-bottom: 200px;
}

.about-hero-left {
  flex: 20%;
}

.about-hero-left .rectangle {
  display: block;
  height: 450px;
  width: 80px;
  background-color: #ffd9d9;
}

section.right {
  flex-direction: row-reverse;
}

section.right .about-hero-left .rectangle {
  float: right;
}

section.right .about-hero-right {
  text-align: right;
  align-items: flex-end;
}

.about-hero-right {
  flex: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.about-hero-right h1 {
  font-size: 3em;
  font-weight: 700;
  color: white;
  margin-top: 50px;
}

.about-hero-right p {
  font-size: 0.9em;
  font-weight: 300;
  color: white;
  line-height: 24px;
  max-width: 530px;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .about-hero-container {
    width: 90%;
  }
  .about-hero-left .rectangle {
    display: none;
  }

  .about-hero-left {
    flex: 0%;
  }

  .about-hero-right {
    flex: 100%;
  }
}

@media screen and (max-width: 576px) {
  .about-hero-right p {
    font-size: 0.8em;
  }
}
