.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
  height: 400px;
}

.image-container {
  position: relative;
  width: 600px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.slider img {
  height: auto;
  max-height: 550px;
  width: 100%;
  object-fit: contain;
}

.slider .arr-left,
.slider .arr-right {
  position: absolute;
  top: 45%;
  color: white;
  background-color: rgba(175, 19, 242);
  font-size: 2rem;
  padding: 20px 10px;
  z-index: 1;
}

.slider .arr-left {
  left: 0;
}

.slider .arr-right {
  right: 0;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.active-slide {
  opacity: 1;
  transition-duration: 1s ease;
}

@media screen and (max-width: 1000px) {
  .slider {
    max-width: 480px;
    width: 100%;
    height: auto;
  }

  .image-container {
    width: 480px;
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .slider {
    width: 100vw;
    height: 300px;
  }

  .slider img {
    max-height: 350px;
    width: 100%;
  }

  .image-container {
    min-width: 350px;
    width: 100%;
    height: 300px;
  }
  .slider .arr-left,
  .slider .arr-right {
    font-size: 1rem;
    padding: 8px 3px;
  }
}
